<template>
  <div>
    <div class="bar">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @mouseleave="mouseLeave"
        @mouseover="mouseOver(item.idnex)"
      >
        <img class="img" :src="item.img" />
        <img
          v-if="index === curIndex"
          class="code animated fadeInDown"
          :src="item.code"
        />
      </div>
      <div class="item" v-if="showTop" @click="toTop">
        <img class="img" src="../assets/img/components/floatBar7.png" />
<!--        <div class="text">返回顶部</div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      curIndex: null,
      list: [
        // {
        //   img: require("../assets/img/components/floatBar1.png"),
        //   code: require("../assets/img/components/code1.png"),
        //   idnex: 0,
        // },
        {
          img: require("../assets/img/components/floatBar2.png"),
          code: require("../assets/img/components/code2.png"),
          idnex: 0,
        },
        {
          img: require("../assets/img/components/floatBar3.png"),
          code: require("../assets/img/components/code3.png"),
          idnex: 1,
        },
      ],
      showTop: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    mouseOver(i) {
      this.curIndex = i;
    },
    mouseLeave() {
      this.curIndex = null;
    },
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
    handleScroll() {
      var scrollTop = document.documentElement.scrollTop;
      if (scrollTop > 300) {
        this.showTop = true;
      } else {
        this.showTop = false;
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less" scoped>
.bar {
  position: fixed;
  right: 0px;
  top: 300px;
  z-index: 101;
  .item {
    width: 60px;
    height: 60px;
    box-shadow: 0px 0px 10px rgba(61, 55, 55, 0.16);
    background: #fff;
    text-align: center;
    position: relative;
    .img {
      margin-top: 15px;
      margin-bottom: 4px;
      width: 30px;
      height: 30px;
    }
    .code {
      position: absolute;
      top: -10px;
      left: -119px;
      cursor: pointer;
    }
  }
  .item:not(:first-child) {
    border-top: 1px solid #ccc;
  }
  .item:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .item:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .item:nth-child(2) {
    .code{
      width: 224px;
      height: 45px;
      left: -224px;
      top: 5px;
    }
  }
  .item:hover {
    background: #ccc;
    opacity: 1;
    .text {
      color: #fff;
    }
  }
}
</style>
