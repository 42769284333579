<template>
  <div class="page">
    <div class="banner">
      <video class="video"  loop="loop"  autoplay="autoplay" muted="muted"  :src="vUrl" >
      </video>
      <div class="text" >
        <div class="text1">MEDICAL MANAGEMENT</div>
        <div class="text2">致力于医疗质量管理持续改进</div>
        <div class="text3">集百家之言 呈竭诚制作</div>
        <div class="text4">根据各个医院的个性化需求灵活优化 高标准满足产品需求及用户体验感</div>
        <div class="line"></div>
        </div>
    </div>
    <indexBom></indexBom>
    <floatBar></floatBar>
  </div>
</template>

<script>
import floatBar from "../../components/FloatBar";
import indexBom from "../../components/IndexBom";
export default {
  metaInfo: {
    title: "南昌多安科技有限公司，多安智慧医务一体化平台，多安不良事件上报平台，多安（杭州）医疗咨询，多安评审系统",
    meta: [
      {
        name: "Index",
        content: "多安科技首页",
      },
    ],
  },
  components: {
    floatBar,
    indexBom,
  },
  props: {},
  data() {
    return {
      vUrl: "//28596643.s21v.faiusr.com/58/ABUIABA6GAAg0tKykAYomqC24wU.mp4",
      // vUrl: require("../../assets/video-tq.mp4"),
      // vUrl: "https://plus2-img.xizi.com/202103/31/lklm-Grdt-MbAL66jLV0ECmZgruG.mp4",
    };
  },
  watch: {},
  computed: {},
  methods: {
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
  },
  created() {
    this.toTop();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  position: relative;
  .banner {
    position: relative;
    width: 100%;
    .video {
      position: relative;
      z-index: -1;
      width: 100%;
    }
    .text{
      bottom: 40%;
      right: 28%;
      position: absolute;
      z-index: 100;
      display: inline-block;

      //text-align: center;
      color: white;

      text-align: left;
      .text1{
        font-size: 18px;
      }
      .text2{
        font-size: 60px;
      }
      .text3{
        font-size: 24px;
      }
      .text4{
        font-size: 24px;
      }
      .line{
        width: 50px;
        height: 2px;
        background: ivory;
        margin-top: 10px;
        margin-bottom: 28px;
      }
    }

  }
}

</style>

