<template>
  <div>
    <div class="content1">
      <div class="top">
        <div class="title">关于我们</div>
        <div class="line"></div>
        <div class="title1">ABOUT US</div>
      </div>
      <div class="bom">
        <div class="left animated" :class="{ bounceInLeft: show1 }">
          <div class="title">
            <div>公司简介</div>
            <div class="line"></div>
          </div>
          <div class="text">
            <div>
              南昌多安科技有限公司成立于2017年，总部座落于世界VR之都——南昌VR虚拟现实产业基地，并在杭州、福州、重庆、西安、贵阳等地设有分公司或办事机构。
            </div>
            <div>
              多安科技多年来砥砺前行，公司旗下产品得到了300+各类别医院的认可，是业界领先的等级医院评审软件及专家咨询服务供应商。我们一直秉承以“提升医疗质量持续改进”为开发思路，致力研发出推动医疗机构精细化改革、提升医院整体运作效率和有利于医疗质量持续改进的医疗软件。致力成为医院评审及质管领域更专业、用户覆盖面更广的高科技软件厂商及智慧型方案提供商。
            </div>
            <div>
              公司以专业的服务能力、卓越的服务态度、创新的服务理念赢得了客户的青睐和信任。
            </div>
          </div>
        </div>
        <div class="right animated" :class="{ bounceInRight: show1 }">
          <Carousel autoplay v-model="value" autoplay-speed="3000" loop arrow="never" radius-dot>
            <CarouselItem>
              <img src="../assets/img/index/swiper1.png" class="swiper" />
            </CarouselItem>
            <CarouselItem>
              <img src="../assets/img/index/swiper2.png" class="swiper" />
            </CarouselItem>
            <CarouselItem>
              <img src="../assets/img/index/swiper3.jpg" class="swiper" />
            </CarouselItem>
            <CarouselItem>
              <img src="../assets/img/index/swiper4.jpg" class="swiper" />
            </CarouselItem>
          </Carousel>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="top">
        <div class="title">企业使命与愿景</div>
        <div class="line"></div>
        <div class="title1">CORPORATE MISSION AND VISION</div>
      </div>
      <div class="bom">
        <div class="item">
          <div class="text textbg1 animated" :class="{ bounceInLeft: show2 }">
            <div class="text1">
              <div class="text1box"></div>
              <div>01</div>
            </div>
            <div class="title">企业使命</div>
            <div class="info">我们一直致力研发医院管理软件，以技术创新为医院管理信息化产业服务。</div>
            <div class="line"></div>
          </div>
          <img
            class="img border1 animated"
            :class="{ bounceInRight: show2 }"
            src="../assets/img/index/img-01.png"
          />
        </div>
        <div class="item">
          <img
            class="img border2 animated"
            :class="{ bounceInLeft: show3 }"
            src="../assets/img/index/img-02.jpg"
          />
          <div class="text textbg2 animated" :class="{ bounceInRight: show3 }">
            <div class="text1">
              <div class="text1box"></div>
              <div>02</div>
            </div>
            <div class="title">企业愿景</div>
            <div class="info">成为医院评审领域专业、用户覆盖面广的专业软件厂商及解决方案提供商。</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="top">
        <div class="title">企业文化</div>
        <div class="line"></div>
        <div class="title1">THE ENTERPRISE CULTURE</div>
      </div>
      <div class="bom">
        <div
          class="item"
          v-for="(item, index) in cultureList"
          :key="index"
          @mouseleave="mouseLeave"
          @mouseover="mouseOver(item.index)"
        >
          <div
            class="outside animated"
            :class="{ fadeOutDown: index == curIndex }"
          >
            <img :src="item.img" class="img" />
            <div class="imgb"></div>
            <div class="text">
              <div>
                <div class="text1">{{ item.text1 }}</div>
                <div class="text2">{{ item.text2 }}</div>
              </div>
            </div>
          </div>
          <div class="inside animated" :class="{ fadeInUp: index == curIndex }">
            <div class="text3">
              {{ item.text3 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content4">
      <div class="top">
        <div class="title color">共同价值观</div>
        <div class="line"></div>
        <div class="title1 color">SHARED VALUES</div>
      </div>
      <div class="bom">
        <div
          class="item"
          :class="{ active: index == curIndex1 }"
          v-for="(item, index) in senseList"
          :key="index"
          @mouseleave="mouseLeave1"
          @mouseover="mouseOver1(item.index)"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
    <div class="content5">
      <div class="top">
        <div class="title">公司荣誉</div>
        <div class="line"></div>
        <div class="title1">COMPANY HONOR</div>
      </div>
      <div class="bom">
        <Carousel v-model="curIndex3" loop arrow="always" dots="none" autoplay autoplay-speed="2500" >
          <CarouselItem>
            <div class="carousel-item">
              <div class="box">
                <div
                        class="item"
                        v-for="(item, index) in honorList1"
                        :key="index"
                >
                  <img class="img" :src="item.img" />
                  <div class="text">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="carousel-item">
              <div class="box">
                <div
                        class="item"
                        v-for="(item, index) in honorList2"
                        :key="index"
                >
                  <img class="img" :src="item.img" />
                  <div class="text">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="carousel-item">
              <div class="box">
                <div
                    class="item"
                    v-for="(item, index) in honorList3"
                    :key="index"
                >
                  <img class="img" :src="item.img" />
                  <div class="text">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="carousel-item">
              <div class="box">
                <div
                    class="item"
                    v-for="(item, index) in honorList4"
                    :key="index"
                >
                  <img class="img" :src="item.img" />
                  <div class="text">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </CarouselItem>
          <CarouselItem>
            <div class="carousel-item">
              <div class="box">
                <div
                    class="item"
                    v-for="(item, index) in honorList5"
                    :key="index"
                >
                  <img class="img" :src="item.img" />
                  <div class="text">{{ item.text }}</div>
                </div>
              </div>
            </div>
          </CarouselItem>
        </Carousel>
      </div>
    </div>
    <div class="content6">
      <div class="top">
        <div class="title">新闻动态</div>
        <div class="line"></div>
        <div class="title1">Company News</div>
      </div>
      <ul class="news-container container-fuild">
        <router-link tag='li' :to="{path:item.path,query:{id:item.id}}" v-for="(item,index) in newsList" :key="index"
                   class="wow fadeIn">
          <div class="content">
            <div>{{item.title}}</div>
            <div>{{item.introduce}}</div>
          </div>
          <div class="time">
           <p>{{ item.date }}</p>
            <span>{{ item.year }}</span>
          </div>
          <div class="circle">
            <img src="../assets/img/circle.png">
            <i class="line center-block"></i>
          </div>
        </router-link>
      </ul>
      <div class="contaianer-fuild text-center more">
        <i class="glyphicon glyphicon-th"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      value: 0,
      cultureList: [
        {
          img: require("../assets/img/index/img11.png"),
          index: 0,
          text1: "精诚共创",
          text2: "Create with sincerity",
          text3:
            "与客户双方真心诚意地合作，携起手来一同前进，共同 创造 辉煌",
        },
        {
          img: require("../assets/img/index/img12.jpg"),
          index: 1,
          text1: "同心共享",
          text2: "Concentric sharing",
          text3:
            "与客户想法高度一致并一同分享合作成果",
        },
        {
          img: require("../assets/img/index/img13.png"),
          index: 2,
          text1: "团结创新",
          text2: "Unity innovation",
          text3: "互相支持，互相配合，互相理解，互相包容。",
        },
        {
          img: require("../assets/img/index/img14.png"),
          index: 3,
          text1: "精益求精",
          text2: "constantly strive for perfection",
          text3:
            "要求对公司的产品不断地进行优化完善，要求做的更好，给客户带来更好的体验。",
        },
      ],
      curIndex: null,
      senseList: [
        { text: "为客户创造价值，成客户之事，就公司之本", index: 0 },
        { text: "清楚自己想要做什么，适合做什么，将要做什么", index: 1 },
        {
          text: "乘众人之智，用众人之力，共同创造价值，共享发展成果",
          index: 2,
        },
        { text: "开放思维，不断超越，拥抱变化", index: 3 },
        {
          text: "忠诚正直，言出必行，行必有果",
          index: 4,
        },
        { text: "尊重我的客户和竞争对手，感谢他们让我更强大", index: 5 },
      ],
      curIndex1: null,
      honorList1: [
        {
          img: require("../assets/img/index/img-honor1.png"),
          text: "南昌多安科技有限公司荣获高新技术企业荣誉",
          index: 0,
        },
        {
          img: require("../assets/img/index/img-honor2.png"),
          text: "医院制度综合管理系统软件著作权",
          index: 1,
        },
        {
          img: require("../assets/img/index/img-honor3.png"),
          text: "多安医院等级评审信息系统软件著作权",
          index: 2,
        },
      ],
      honorList2: [
        {
          img: require("../assets/img/index/img-honor4.png"),
          text: "不良事件上报管理系统软件著作权",
          index: 3,
        },
        {
          img: require("../assets/img/index/img-honor5.png"),
          text: "互联网医院系统软件著作权",
          index: 4,
        },
        {
          img: require("../assets/img/index/img-honor6.png"),
          text: "医院创建制度管理系统软件著作权",
          index: 5,
        },
      ],
      honorList3:[
        {
          img: require("../assets/img/index/img-honor7.png"),
          text: "质量管理体系认证证书",
          index: 6,
        },
        {
          img: require("../assets/img/index/img-honor8.png"),
          text: "龙湾区第一人民医院嘉奖涵",
          index: 7,
        },
        {
          img: require("../assets/img/index/img-honor9.png"),
          text: "万州区妇幼保健院嘉奖涵",
          index: 8,
        },
      ],
      honorList4:[
        {
          img: require("../assets/img/index/img-honor10.png"),
          text: "温岭妇幼保健院嘉奖涵",
          index: 9,
        },
        {
          img: require("../assets/img/index/img-honor11.png"),
          text: "余姚中医院嘉奖涵",
          index: 10,
        },
        {
          img: require("../assets/img/index/img-honor12.png"),
          text: "柯桥区中医医院嘉奖涵",
          index: 11,
        },
      ],
      honorList5:[
        {
          img: require("../assets/img/index/img-honor13.png"),
          text: "安吉县第三人民医院嘉奖涵",
          index: 12,
        },
        {
          img: require("../assets/img/index/img-honor14.png"),
          text: "开化县中医院嘉奖涵",
          index: 13,
        },
        {
          img: require("../assets/img/index/img-honor15.png"),
          text: "诸暨市妇幼保健院签收单",
          index: 14,
        },
      ],
      curIndex2: null,
      curIndex3: 0,
      num: 0,
      moveL: false,
      moveR: false,
      show1: false,
      show2: false,
      show3: false,
      newsList: [
        {
          id: 'https://mp.weixin.qq.com/s/VizwPojBwhT6oB6SfoIBAw',
          title: '助力三甲复审|新余市人民医院上线“多安”不良事件上报管理系统',
          date: '02-18',
          year: '2024',
          path: '/demo'
        },
       {
          id: 'https://mp.weixin.qq.com/s/_g1Tc7GlL7NvRVuCvbftLw',
          title: '助力医院评审上分！郑州市第一人民医院上线多安医疗质量指标监测管理平台',
          date: '01-18',
          year: '2024',
          path: '/demo'
        },
       {
          id: 'https://mp.weixin.qq.com/s/_cfxU7qaW0TcNzzbmvYPzQ',
          title: '专业成就价值！新疆疏勒县人民医院上线多安不良事件上报系统',
          date: '12-18',
          year: '2023',
          path: '/demo'
        },
       {
          id: 'https://mp.weixin.qq.com/s/wdsGQNb_s_4OYpdlJOX6xQ',
          title: '以服务铸就口碑！莆田市第一医院上线多安医院评审管理平台',
          date: '12-14',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/9Zvj44dFsfnTMJkOBwmugw',
          title: '合作共赢+1，抚州三院上线多安等级医院评审系统',
          date: '12-07',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/2XIRTal07uvuHJMgFEdtTg',
          title: '多安打造医疗质量监测管理平台，助力郑大二附等级评审二类指标采集更easy！',
          date: '10-27',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/viwdcZ9CAPqjsxma9mrovQ',
          title: '携手共进！南大口腔上线多安医疗质量精细化管理平台',
          date: '10-18',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/U9LPcR5yJKrLTl11pf-Wlw',
          title: '医务精细化管理|赣州市人民医院上线医务管理系统，让技术授权形成闭环！',
          date: '09-28',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/mHrHWVvYzlf0cjVpCdATng',
          title: '助力三甲复评，玉山县中医院上线多安医院创建管理系统！',
          date: '09-05',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/J7IcDGCpX-571n207KehHg',
          title: '合作共赢！三明市第一医院上线多安等级评审系统',
          date: '08-14',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/QuNC8fMHRbTr3SLpMCCzng',
          title: '多安医务|绍兴二院上线医务管理系统，为"医管”工作增效减负！',
          date: '07-21',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/x7YkKKDfUJwzyKCFxHQTmA',
          title: '携手共进！松阳吴苏君医院上线多安等级评审系统！',
          date: '06-25',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/08fVbuChEVkK8Sfe41Bzkg',
          title: '再创佳绩！多安助力文成中医院二乙中医院复评工作顺利推进！',
          date: '06-19',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/WPN36ItKIro7f-K8Jr2_-w',
          title: '“创二甲综合医院” 福建长乐第二医院上线多安“智慧等级评审迎评系统”',
          date: '06-02',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/yM-TLs1xQxSFpLiTyqRZAA',
          title: '嵊州市第五医院上线多安“智慧评审管理平台”',
          date: '05-09',
          year: '2023',
          path: '/demo'
        },
         {
          id: 'https://mp.weixin.qq.com/s/tV4cjiaTW_PWtF9UIWBtpg',
          title: '衢江区妇幼保健院上线多安“智慧评审制度管理平台”',
          date: '04-25',
          year: '2023',
          path: '/demo'
        },
         {
          id: 'https://mp.weixin.qq.com/s/9u1c7iih3Bmg5FF_9NeYiA',
          title: '创建三级综合医院 宁波龙赛医院上线多安“智慧等级迎评管理系统”',
          date: '03-29',
          year: '2023',
          path: '/demo'
        },
         {
          id: 'https://mp.weixin.qq.com/s/mSN-THSMqH_tM8KJxz3iEA',
          title: '安溪县中医院上线“等评管控一体化”平台',
          date: '03-18',
          year: '2023',
          path: '/demo'
        },
         {
          id: 'https://mp.weixin.qq.com/s/uxbnQ0JhEI6WVvcY23GMKw',
          title: '鄞州三院上线“等级评审制度管理系统”',
          date: '03-13',
          year: '2023',
          path: '/demo'
        },
         {
          id: 'https://mp.weixin.qq.com/s/aDh_33aVhLhbA1q4rgL_zg',
          title: '与富阳市人民医院携手打造医务综合管理平台',
          date: '03-10',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/_rUPK8UK4jJmw8UCASYwKQ',
          title: '助力等级评审常态化管理|仙居县中医院上线多安‘等评一体化管理平台”',
          date: '03-06',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/htZ1vWMkiZYq3nkm8Nkoag',
          title: '多安“等级评审一体化管理平台”助力台州黄岩区中医院二甲复评',
          date: '03-02',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/MP5_ZoWEk69vKGC3JAJ5HA',
          title: '加强质管文化建设 浙江岱山县第一医院上线多安“不良事件上报分析平台”',
          date: '01-06',
          year: '2023',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/rCsqQUB8uVnZoNoeo-f2JA',
          title: '温岭仁谐康复医院上线多安“等级创建一体化管理平台”',
          date: '12-23',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/rokR6ZscMZYGJElwLXXmTg',
          title: '助力“二甲复评" 兴国县妇幼保健院上线多安“等级评审一体化管理平台”',
          date: '12-09',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/844rlgy7jD1GfsUjVbCZEw',
          title: '多安“等级创建一体化平台”助力婺城区人民医院“创二甲”',
          date: '12-02',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/wsKDiAJsWCREbCi8M-UfNA',
          title: '基于等级评审 深入医务日常管理 温州滨海医院上线多安“智慧医务质管一体化平台”',
          date: '11-25',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/lIXVPApCiV2vmgUfTokOmA',
          title: '助力等级评审常态化管理 湖州南太湖医院上线多安‘等评一体化管理平台”',
          date: '11-18',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/mVBjcuqietljMhMe7JeOcw',
          title: '东阳市第七医院上线多安“等级创建制度一体化管理平台”',
          date: '11-11',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/7I8dADLLobS4dymrE2xCtA',
          title: '以评促建提医管 义乌稠州医院上线“多安智慧医务质管一体化平台”',
          date: '11-04',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/sHovc0L8Fvc6stq4Mh07hg',
          title: '争创“二甲” 临安区第五医院上线多安“等级创建制度一体化管理平台”',
          date: '10-28',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/saEJ81YrL73BunlLXyE2FQ',
          title: '仙居县第五（精神专科）医院上线多安“等评管控一体化”平台',
          date: '10-24',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/dPeCj4TyWgz19F6PMUK8jw',
          title: '单病种质量管理智能上报系统 真正“AI" 才更高效！',
          date: '10-19',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/eGCbHZjLneQEMn1b3Od5rg',
          title: '以评促建提医管 温岭市第五医院上线多安“等级医院评审智慧管理系统”',
          date: '10-14',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/y7bGlVOSlNIa6UKq1yBO9Q',
          title: '助力等级评审管理改进 黄岩区第三医院上线“多安等级医院评审智慧管理系统”',
          date: '10-10',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/4ZsiH17gEXx99IuFkTJZrw',
          title: '海宁市第二医院上线多安“等级评审一体化管理系统”',
          date: '9-30',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/Dn-WQIM1bnfMR3IzjAOdFA',
          title: '“争创”二甲 临安区第四医院上线多安“等级创建一体化管理平台”',
          date: '9-23',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/pIIlTAsreYaXfMdoPM9BlA',
          title: '“争创”二甲 临安区第三医院上线多安“等级创建一体化管理平台”',
          date: '9-16',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/fzoPfNHz46JH8vadhj2b7w',
          title: '多安“等评制度管理系统”上线重庆市铜梁区中医院',
          date: '9-05',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/C2-shjzrIepzsoMnD8w2UQ',
          title: '海盐县中医院上线多安“等级评审制度管理系统”',
          date: '8-22',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/uHSMaZJITV-r1LszTOjXyA',
          title: '‘多安“医务管理系统，让数据多跑路，让平台擅分析！',
          date: '8-17',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/YaUR9i1cm4EWQDlXGBP7AQ',
          title: '多安“等级创建制度管理系统”助力萍矿总医院新一轮三甲复评',
          date: '8-5',
          year: '2022',
          path: '/demo'
        },
          {
        id: 'https://mp.weixin.qq.com/s/xgGPpmuw7ofDd4u37IP2aA',
        title: '捍卫“二甲”成果 淳安县妇保院上线多安“等级创建制度管理系统”',
        date: '7-15',
        year: '2022',
        path: '/demo',
          },
        {
          id: 'https://mp.weixin.qq.com/s/QxZQLVUFS_lnxyh8ym5kvw',
          title: '花开三度 浙江省龙港市人民医院上线”等级创建制度”以及“医疗不良事件监测”双平台',
          date: '6-17',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/RDGXBJvF5D3LlYCEsaYnTg',
          title: '捍卫“二甲”成果 武义县妇保院上线多安“等级创建制度管理系统”',
          date: '6-14',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/9KdS-0tkTifLrnaXfTDeDg',
          title: '浙江省浦江县第二医院引进多安“等级医院评审管理平台”',
          date: '6-13',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/P-YD67jr15es0zakmf3O8w',
          title: '携手”双一流“赣州市人民医院 打造AI医疗质管监测平台',
          date: '6-2',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/HgajplHMdldGK1vZyCP1Mw',
          title: '台州市肿瘤医院引进多安“创建制度管理系统”',
          date: '5-9',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/2oqcSApIoGiifFEIMkYSMA',
          title: '做强大专科 浙江天仙骨科医院上线多安“等级创建一体化平台”',
          date: '4-25',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/xoV7QcjAYnppdBDaCl42fA',
          title: '路桥区第三人民医院上线多安“等级医院评审一体化平台”',
          date: '4-18',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/GQP73_iXsfkLLmd5uaviDw',
          title: '婺城区第一人民医院上线多安“等级医院评审一体化平台”',
          date: '4-11',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/oRF_lGrhR402vLU2mHylWg',
          title: '浙江省浦江县中医医院上线多安“等级评审一体化平台”',
          date: '4-6',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/Kj1739Z8fYG4eGYMRlVo_Q',
          title: '德清县第三人民医院上线多安“等级医院评审管理系统”',
          date: '3-28',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/0cKYmapL247frwNd3Vyuyg',
          title: '桐乡市第二人民医院上线多安“等级医院评审制度管理系统”',
          date: '3-22',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/mWaI87yEBTr4VeM0UwBRlQ',
          title: '“多安”等级评审制度管理系统中标湖州市菱湖医院',
          date: '3-4',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/hZGDY1fVpcCCE7syRXA95Q',
          title: '遂昌县中医院迎“二甲复评” 上线“多安”等级创建管理系统',
          date: '2-14',
          year: '2022',
          path: '/demo'
        },
        {
        id: 'https://mp.weixin.qq.com/s/Bd7Uw6nC-Bz8GvHkxw-d6A',
        title: '浙江吴兴区人民医院上线多安”等级创建制度管理系统”',
        date: '1-24',
        year: '2022',
        path: '/demo'
       },
        {
          id: 'https://mp.weixin.qq.com/s/G31qGkhAor87dCSHJeGchQ',
          title: '“多安”医院等级创建制度管理系统助理金华市婺城区人民医院“创二甲”',
          date: '1-17',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/XT2t54PfU-JbU-fge0oFRA',
          title: '乐清市人民医院携手多安不良事件管理软件 让医院安全质管更规范 更及时。',
          introduce: '',
          date: '1-10',
          year: '2022',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/g1qH5zkJTkpDto_lLTL5mA',
          title: '让“医院管理”更具制度化、流程化， 嵊州市人民医院引进多安“等级创建制度管理平台”',
          introduce: '',
          date: '12-27',
          year: '2021',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/d5Zso27zFgTqdnycAITlTg',
          title: '让“等评理念”落地易且规范 苍南县第三医院引进多安“等级创建制度管理平台”',
          introduce: '',
          date: '12-20',
          year: '2021',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/gsKLZawhMbww1oB7Vkgvuw',
          title: '专家现场迎检时，“应知应会”具体会问哪些？（医技部分——药事、门诊、检验科、总务科、）',
          introduce: '',
          date: '12-17',
          year: '2021',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/gsKLZawhMbww1oB7Vkgvuw',
          title: '为高效推进\'三甲复评\' 金华市第二医院引进多安\'等级创建制度管理平台\'',
          introduce: '',
          date: '12-13',
          year: '2021',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/pCDFNfLCW3UplTuohztchA',
          title: '专家现场迎检时，“应知应会”具体会问哪些？（医技部分——医务、质控、护理、科教、院感）',
          introduce: '',
          date: '12-06',
          year: '2021',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/jCVUTjrA8XSh-K560IeMmA',
          title: '平阳中医院上线多安等级评审制度管理系统',
          introduce: '',
          date: '12-02',
          year: '2021',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/KHN4M19-M3OIGzGb9ARNGg',
          title: '浙江省浦江县妇幼保健院上线多安\'等级医院评审管理系统\'',
          introduce: '',
          date: '11-29',
          year: '2021',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/oDZZjZqHh7_Vvhbwu-bgtQ',
          title: '专家现场迎检时，“应知应会”具体会问哪些？（医疗部分——党办、财务、人事、院办、保卫、医患、防保、营养、医保、病理）',
          introduce: '',
          date: '11-26',
          year: '2021',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/QEwkzzbRt8nBs2rGWi3qxg',
          title: '他山之石，可以攻玉——急诊医生如何《识别急诊陷阱》！',
          introduce: '',
          date: '11-24',
          year: '2021',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/i9depEPIPuxmhlNHF2xEJg',
          title: '专家现场迎检时，“应知应会”具体会问哪些？（医疗部分——药学、门诊、检验、总务、信息、设备、监察、招标、宜联）',
          introduce: '',
          date: '11-23',
          year: '2021',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/CWAafrn17lgstIUOlO4oHw',
          title: '为合同审批管理更高效 常山县人民医院引进多安合同审批管理平台',
          introduce: '',
          date: '11-22',
          year: '2021',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/joieYvLTcHy1if6Mq5hjsg',
          title: '浙江省武义县中医院签约“多安” 等级创建制度管理平台',
          introduce: '',
          date: '11-20',
          year: '2021',
          path: '/demo'
        },
        {
          id: 'https://mp.weixin.qq.com/s/UHzkOGHh8Isx0yqPxp8VLg',
          title: '常山县中医院签约“多安” 等级创建制度管理平台',
          introduce: '',
          date: '11-19',
          year: '2021',
          path: '/demo'
        },
      ]

    };
  },
  watch: {},
  computed: {},
  methods: {
    mouseOver(i) {
      this.curIndex = i;
    },
    mouseLeave() {
      this.curIndex = null;
    },
    mouseOver1(i) {
      this.curIndex1 = i;
    },
    mouseLeave1() {
      this.curIndex1 = null;
    },
    leftLeave() {
      this.moveL = false;
    },
    leftOver() {
      this.moveL = true;
      let time = setInterval(() => {
        if (this.moveL) {
          if (this.num > -740) {
            this.num -= 2;
          }
        } else {
          clearInterval(time);
        }
      }, 10);
    },
    rightLeave() {
      this.moveR = false;
      this.time = null;
    },
    rightOver() {
      this.moveR = true;
      let time = setInterval(() => {
        if (this.moveR) {
          if (this.num < 0) {
            this.num += 2;
          }
        } else {
          clearInterval(time);
        }
      }, 10);
    },
    handleScroll() {
      var scrollTop = document.documentElement.scrollTop;
      // console.log(scrollTop);
      if (scrollTop >= 200) {
        this.show1 = true;
      }
      if (scrollTop >= 850) {
        this.show2 = true;
      }
      if (scrollTop >= 1140) {
        this.show3 = true;
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less" scoped>
.top {
  text-align: center;
  .title {
    color: #000028;
    font-size: 24px;
  }
  .line {
    width: 40px;
    height: 1px;
    background: #ef8200;
    opacity: 1;
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 7px;
  }
  .title1 {
    font-size: 14px;
    color: #999999;
    margin-bottom: 50px;
  }
}
.content1 {
  width: 100%;
  height: 689px;
  margin: 0 auto;
  padding-top: 70px;
  .bom {
    margin-top: 60px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    .left {
      width: 40%;
      height: 400px;
      margin-left: 40px;
      .title {
        color: #4d4d4d;
        font-size: 20px;
        .line {
          width: 40px;
          height: 3px;
          background: #ef8200;
          opacity: 1;
          margin-top: 10px;
        }
      }
      .text {
        margin-top: 35px;
        color: #000014;
        line-height: 30px;
        font-size: 16px;
        letter-spacing: 2px;
        text-align: justify;
        text-indent: 2em;
      }
    }
    .right {
      width: 53%;
      height: 420px;
      margin-right: 1%;
      /deep/.ivu-carousel-list {
        width: 800px;
        height: 420px;
        border-radius: 10px !important;
      }
      .swiper {
        width: 800px;
        height: 420px;
        border-radius: 10px !important;
      }
    }
  }
}
.content2 {
  width: 100%;
  height: 770px;
  background: #f0f5fa;
  padding-top: 30px;
  /*justify-content: center;*/
  .bom {
    width: 100%;
    //margin-left: 5%;
    height: 580px;
    margin-top: 60px;

    .item {
      width: 100%;
      height: 280px;
      margin-bottom: 10px;
      display: flex;


      .img {
        width: 70%;
        height: 300px;
      }
      .border1 {
        border-top-right-radius: 10px;
      }
      .border2 {
        border-bottom-left-radius: 10px;
      }

      .text {
        width: 30%;
        height: 300px;
        color: #fff;
        .text1 {
          margin-top: 60px;
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          margin-left: 50px;
          margin-right: 42px;
          .text1box {
            width: 22px;
            height: 4px;
            background: #fff;
          }
        }
        .title {
          font-size: 20px;
          margin-left: 50px;
          margin-right: 42px;
          margin-top: 60px;
        }
        .info {
          margin-top: 10px;
          font-size: 16px;
          margin-left: 50px;
          margin-right: 42px;
        }
        .line {
          margin-top: 24px;
          width: 400px;
          height: 1px;
          background: #fff;
        }
      }
      .textbg1 {
        background: #10b4fc;
        border-top-left-radius: 10px;
      }
      .textbg2 {
        background: #ef8200;
        border-bottom-right-radius: 10px;
      }
    }
  }
}
.content3 {
  padding-top: 70px;
  width: 100%;
  height: 760px;
  .bom {
    width: 100%;
    height: 500px;
    margin: 0 auto;
    display: flex;
    margin-top: 60px;

    /*justify-content: space-between;*/
    .item {
      overflow: hidden;
      width: 21%;
      height: 500px;
      border-radius: 10px;
      margin-left: 50px;
      .outside {
        z-index: 10;
        position: relative;
        border-radius: 10px;
        .img {
          width: 100%;
          height: 500px;
          position: absolute;
          z-index: 10;
        }
        .imgb {
          width: 100%;
          height: 500px;
          background: rgba(0, 64, 159, 0.5);
          position: absolute;
          z-index: 11;
        }
        .text {
          width: 100%;
          height: 500px;
          position: absolute;
          z-index: 20;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          .text1 {
            font-size: 20px;
            font-weight: 500;
            text-align: center;
          }
          .text2 {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .inside {
        position: relative;
        z-index: 9;
        width: 100%;
        height: 500px;
        background: #ef8200;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        .text3 {
          text-align: justify;
          height: 220px;
          width: 262px;
          line-height: 24px;
          color: #ffffff;
          font-size: 16px;
          border-top: 2px solid #fff;
          border-bottom: 2px solid #fff;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
.content4 {
  width: 96%;
  height: 402px;
  background-image: url("../assets/img/index/content-bg.png");
  background-repeat: no-repeat;
  background-size: auto;
  padding-top: 30px;
  margin-left: 2%;
  .color {
    color: #fff;
  }
  .bom {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 60px;
    .item {
      width: 30%;
      height: 68px;
      text-align: center;
      line-height: 68px;
      background: #ffffff;
      opacity: 1;
      border-radius: 10px;
      margin-bottom: 33px;
      padding-left: 40px;
      font-size: 14px;
      color: #000028;
    }
    .active {
      position: relative;
      animation: mymove 0.5s;
      animation-fill-mode: forwards;
    }
    @keyframes mymove {
      from {
        top: 0px;
      }
      to {
        top: -10px;
      }
    }
    .item:hover {
      box-shadow: 0px 0px 6px rgba(16, 180, 252, 0.79);
    }
  }
}
.content6 {
  width: 90%;
  height: 100%;
  padding-top: 70px;
  margin-bottom: 80px;
  margin-left: 5%;
  .title{
    text-align: center;
    font-size: 24px;
  }
  .news-container {
    overflow: hidden;
    margin-bottom: 0;
    margin-left: 200px;
    margin-right: 200px;
  }

  .news-container > li {
    width: 55.6%;
    height: 120px;
    float: left;
    color: #333;
    text-align: left;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }

  .news-container > li:hover {
    color: #1e73be;
    border: 1px solid #1e73be;
    cursor: pointer;
  }

  .news-container > li:nth-of-type(2n) {
    float: right;
    text-align: left;
  }

  .news-container > li > .content {
    width: 60%;
    float: left;
    padding: 20px 0;
  }

  .news-container > li > .time {
    width: 20%;
    float: left;
    padding: 10px 0;
  }

  .news-container > li > .time > p {
    font-size: 30px;
    margin: 5px 0;
  }

  .news-container > li > .circle {
    width: 20%;
    height: 100%;
    float: left;
    position: relative;
  }

  .news-container > li > .circle > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
  }

  .news-container > li > .circle > i {
    display: block;
    width: 1px;
    height: 100%;
    background: #707070;
  }

  .news-container > li:nth-of-type(2n) > .content {
    float: right;
  }

  .news-container > li:nth-of-type(2n) > .time {
    float: right;
  }

  .news-container > li:nth-of-type(2n) > .circle {
    float: right;
  }

  .news-container > li:nth-of-type(1) > .circle > i {
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .news-container > li:nth-of-type(n+1) > .circle > i {
    height: 100%;
    position: absolute;
    top :50%;
    left: 50%;
  }
  .news-container > li:last-of-type > .circle > i {
    height: 100%;
    position: absolute;
    top :50%;
    bottom: 0%;
    left: 50%;
    display: none;
  }
  .more {
    font-size: 25px;
    color: #707070;
  }

  .more > i {
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    .news-container > li {
      width: 100%;
    }

    .news-container > li > .content {
      width: 70%;
      text-align: left;
      float: right;
    }

    .news-container > li > .time {
      width: 30%;
      text-align: left;
      float: right;
    }

    .news-container > li > .circle {
      display: none;
    }
  }
}
.content5 {
  width: 100%;
  height: 655px;
  background: #f0f5fa;
  padding-top: 30px;
  .bom {
    margin: 0 auto;
    width: 100%;
    height: 402px;
    margin-top: 60px;
    .carousel-item {
      width: 100%;
      height: 402px;
      .box {
        display: flex;
        justify-content: space-around;
        width: 1104px;
        height: 500px;
        margin: 0 auto;
        .item {
          width: 340px;
          border-radius: 10px;
          background: #fff;
          img {
            width: 340px;
            height: 308px;
          }
          .text {
            width: 340px;
            height: 132px;
            color: #000014;
            font-size: 16px;
            display: flex;
            padding-left: 34px;
            padding-right: 34px;
          }
        }
      }
    }
  }
}
</style>
